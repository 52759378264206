import API from '../../_apis';
import {
  CREATE_POST,
  CREATE_POST_ID,
  FETCH_POST_BY_TITLE,
  FETCH_POSTS,
  IS_EDITING,
  SET_POST, UPDATE_POST,
} from '../../../constants';

export const fetchPosts = () => async dispatch => {
  const response = await API.get('/post');
  dispatch({ type: FETCH_POSTS, payload: response.data });
};

export const fetchPostByTitle = (title) => async dispatch => {
  const response = await API.get(`/post/title?title=${title}`);
  dispatch({ type: FETCH_POST_BY_TITLE, payload: response.data });
};

export const setPost = (post) => async dispatch => {
  dispatch({ type: SET_POST, payload: post });
};

export const createPost = (post) => async dispatch => {
  const response = await API.post('/post/create', post);
  dispatch({ type: CREATE_POST, payload: response.data });
};

export const createPostId = () => async dispatch => {
  const response = await API.get('/post/create-id');
  dispatch({ type: CREATE_POST_ID, payload: response.data });
};

export const updateIsEditing = (isEditing) => async dispatch => {
  dispatch({ type: IS_EDITING, payload: isEditing });
};

export const updatePost = (post) => async dispatch => {
  const response = await API.post(`/post/update/${post._id}`, post);
  dispatch({ type: UPDATE_POST, payload: response.data });
};
