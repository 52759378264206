import React from 'react';
import './PostPage.scss';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import twitterBlueIcon from '../../../assets/twitter-blue-icon.svg';
import githubBlueIcon from '../../../assets/github-blue-icon.svg';
import Tag from '../../Tag/Tag';
import { fetchPostByTitle, updateIsEditing } from '../../../_redux/actions';
import { connect } from 'react-redux';
import QuickHoverAccess from '../../QuickHoverAccess/QuickHoverAccess';
import Highlight from 'react-highlight';
import { ADMIN_USER_ACCESS } from '../../../../constants';

const renderLoadingSpinner = (isLoading) => {
  if (isLoading) {
    return (
      <div className="loading-spinner-container">
        <CircularProgress className="loading-spinner"/>
      </div>
    );
  }
};

const renderTags = (tags) => {
  if (tags) {
    return (
      <div className="tags-container">
        {tags.slice(0, 4).map((tag, index) =>
          <Tag key={index}
               name={tag}/>)}
      </div>
    );
  }
};

const toggleCommentsView = () => {
  console.log('toggleCommentsView');
};

const openToolbarIconLink = (url) => window.open(url, '_blank');

const PostPage = (props) => {
  const {
    fetchPostByTitle, history, updateIsEditing,
    userAccessLevel,
  } = props;
  let { post } = props;
  const { id: routeId } = props.match.params;
  const { state } = props.location;

  if (!post) {
    // TODO: // Move this to a post model or something
    post = {
      _id: '',
      body: '',
      description: '',
      createdAt: '',
      updatedAt: '',
      tags: [],
      title: '',
      postType: '',
      imageUrl: '',
      author: {
        _id: '',
        firstName: '',
        lastName: '',
      },
    };
  }

  const { body, createdAt, tags, title, updatedAt } = post;
  const { firstName, lastName } = post.author;

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    // NOTE: We have to use `props.post` instead of just post
    // or we don't get the appropriate response
    // ----------------------------------------
    // If we don't have a post in the props that means
    // that the user navigated directly here by pasting a URL
    if (!props.post) {
      setIsLoading(true);
      fetchPostByTitle(routeId)
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }
  }, []);

  const editPost = async () => {
    await updateIsEditing(true);
    history.push('/create-post')
  };

  // NOTE: This only checks access level and not who the post
  // belongs too - so that means any 'admin' can edit any post
  const canUserEditPost = () => userAccessLevel >= ADMIN_USER_ACCESS;

  return (
    <div className="post-page">
      <QuickHoverAccess showHomeButton
                        showThoughtsButton
                        showTutorialsButton
                        history={history}
      />
      <div className="post-content">
        {renderLoadingSpinner(isLoading)}

        <div className="post-header-container">
          <div className="post-title">
            {title}

            {canUserEditPost() &&
            <Button className="edit-button"
                    onClick={editPost}>
              Edit
            </Button>
            }
          </div>
          <div className="post-subtitle">
            <div className="post-author">
              {firstName} {lastName}
            </div>
            <span className="spacer"/>
            <div className="post-timestamps-container">
              <div className="post-timestamp">
                {moment(createdAt).format('MMMM Do, YYYY')}
              </div>
              {createdAt !== updatedAt && (
                <div className="post-timestamp">
                  Updated:
                  {moment(updatedAt).format('MMMM Do, YYYY')}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="post-body">
          <Highlight innerHTML={true}>
            {body}
          </Highlight>

          <div className="show-comments-button-container">
            <Button className="normal-button"
                    onClick={toggleCommentsView}>
              Show Comments
            </Button>


            <div className="tags-footer-container">
              {renderTags(tags)}

              <div className="links-container">
                <img className="toolbar-icon"
                     src={twitterBlueIcon}
                     alt="github icon"
                     onClick={() => openToolbarIconLink('https://twitter.com/fernando7_r')}/>

                <img className="toolbar-icon"
                     src={githubBlueIcon}
                     alt="github icon"
                     onClick={() => openToolbarIconLink('https://github.com/FernandoX7')}/>
              </div>
              <div className="divider-line"></div>
            </div>
          </div>

          <div className="divider-line"/>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  post: state.posts.post,
  userAccessLevel: state.user && state.user.accessLevel ?
    state.user.accessLevel :
    null,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPostByTitle: (post) => dispatch(fetchPostByTitle(post)),
  updateIsEditing: (isEditing) => dispatch(updateIsEditing(isEditing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostPage);
