import React from 'react';
import './SplitFooter.scss';
import mindMapiconInverted from '../../assets/mind-map-icon-inverted.svg';
import aiIcon from '../../assets/ai-icon.svg';
import Button from '@material-ui/core/Button';

const goToPage = (page) => {
  console.log('TODO: Go to page', page);
};

const SplitFooter = () => {
  return (
    <div className="split-footer">
      <div className="left-side">
        <div className="info-layout">
          <div className="horizontal-layout">
            <div className="type-header">
              Thoughts
            </div>
            <img className="type-icon"
                 alt="thoughts mind map icon"
                 src={mindMapiconInverted}/>
          </div>

          <div className="posts-label">
            Posts
          </div>
          <div className="posts-count">
            76
          </div>

          <div className="horizontal-layout">
            <div className="last-update-container">
              <div className="last-update-label">
                last update
              </div>
              <div className="last-update-date-label">
                June 30, 2019
              </div>
            </div>
            <Button className="explore-button"
                    variant="outlined"
                    onClick={() => goToPage('thoughts')}>
              Explore
            </Button>
          </div>
        </div>
      </div>

      <div className="right-side">
        <div className="info-layout">
          <div className="horizontal-layout">
            <div className="type-header">
              Tutorials
            </div>
            <img className="type-icon"
                 alt="brain icon"
                 src={aiIcon}/>
          </div>

          <div className="posts-label">
            Posts
          </div>
          <div className="posts-count">
            44
          </div>

          <div className="horizontal-layout">
            <div className="last-update-container">
              <div className="last-update-label">
                last update
              </div>
              <div className="last-update-date-label">
                April 2, 2019
              </div>
            </div>
            <Button className="explore-button dark-border-color"
                    variant="outlined"
                    onClick={() => goToPage('tutorials')}>
              Explore
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitFooter;
