import API from '../../_apis';
import { CREATE_USER_IN_DB, GET_USER_BY_FIREBASE_ID } from '../../../constants';

export const getUserByFirebaseId = (id) => async dispatch => {
  const response = await API.get(`/user/firebase/${id}`);
  dispatch({ type: GET_USER_BY_FIREBASE_ID, payload: response.data });
};

export const saveUserToDb = (user) => async dispatch => {
  const response = await API.post('/user/create', user);
  dispatch({ type: CREATE_USER_IN_DB, payload: response.data });
};

