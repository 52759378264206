import React from 'react';
import '../../../app.scss';
import NewPosts from '../../NewPosts/NewPosts';
import SplitFooter from '../../SplitFooter/SplitFooter';

const HomePage = (props) => {
  const { history } = props;
  return (
    <React.Fragment>
      <NewPosts history={history}/>
      <SplitFooter/>
    </React.Fragment>
  );
};

export default HomePage;
