import React from 'react';
import './header.scss';
import appLogoInverted from '../../assets/app-logo-inverted.svg';
import appLogo from '../../assets/app-logo.svg';
import searchIconInverted from '../../assets/search-icon-inverted.svg';
import searchIcon from '../../assets/search-icon.svg';
import sendEmailIconInverted from '../../assets/send-email-icon-inverted.svg';
import sendEmailIcon from '../../assets/send-email-icon.svg';
import twitterIconInverted from '../../assets/search-icon-inverted.svg';
import twitterIcon from '../../assets/twitter-icon.svg';
import githubIconInverted from '../../assets/github-icon-inverted.svg';
import githubIcon from '../../assets/github-icon.svg';

const navigateHome = () => {
  console.log('TODO: Navigate home using react router');
};

const showSearchBarOnPage = () => {
  console.log('TODO: Emit show search bar on page event');
};

const goToFeedbackPage = () => {
  console.log('TODO: Go to feedback page');
};

const openToolbarIconLink = (url) => window.open(url, '_blank');

// TODO: Update toolbar colors on route changes after implementing react router
const updateToolbarBackgroundColor = () => {
  console.log('TODO: Update toolbar colors on route changes after implementing react router');
  // this.router.events
  //   .subscribe((event: any) => {
  //     if (event.url) {
  //       const routeName = event.url.split('/')[1];
  //       switch (routeName) {
  //         case 'home':
  //           this.backgroundColor = 'white';
  //           this.showInvertedColors = false;
  //           break;
  //         case 'post':
  //           this.backgroundColor = '#EEEFF3';
  //           this.showInvertedColors = false;
  //           break;
  //         case 'thoughts':
  //           this.backgroundColor = '#2677C3';
  //           this.showInvertedColors = true;
  //           break;
  //         case 'tutorials':
  //           this.backgroundColor = '#EEEFF3';
  //           this.showInvertedColors = false;
  //           break;
  //       }
  //     }
  //   });
};

const Header = () => {
  const showInvertedColors = false; // TODO: Change to state with other colors

  React.useEffect(() => {
    updateToolbarBackgroundColor();
  }, []);

  return (
    <div className="header">
      <div className="left-side">
        <img className="toolbar-logo"
             alt="website logo"
             src={showInvertedColors ? appLogoInverted : appLogo}
             onClick={navigateHome}/>
        <div className={showInvertedColors ? 'social-media-handle inverted-text-color' : 'social-media-handle'}>
          @fernando7_r
        </div>
      </div>

      <span className="spacer"/>

      <div className="right-side">
        <img className="toolbar-icon"
             alt="search icon"
             src={showInvertedColors ? searchIconInverted : searchIcon}
             onClick={showSearchBarOnPage}/>
        <img className="toolbar-icon"
             alt="send email icon"
             src={showInvertedColors ? sendEmailIconInverted : sendEmailIcon}
             onClick={goToFeedbackPage}/>
        <img className="toolbar-icon"
             alt="twitter icon"
             src={showInvertedColors ? twitterIconInverted : twitterIcon}
             onClick={() => openToolbarIconLink(' https://twitter.com/fernando7_r')}/>
        <img className="toolbar-icon"
             alt="github icon"
             src={showInvertedColors ? githubIconInverted : githubIcon}
             onClick={() => openToolbarIconLink(' https://github.com/FernandoX7')}/>
      </div>
    </div>
  );
};

export default Header;
