import React from 'react';
import './MainFooter.scss';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';

const goToSection = (pageSection) => {
  console.log('TODO: Go to section', pageSection);
};

const registerEmailForUpdates = () => {
  console.log('TODO: Register email for updates');
};

const getCurrentYear = () => moment().format('YYYY');

const MainFooter = () => {
  return (
    <div className="main-footer">
      <div className="left-side">
        <div className="vertical-button-container">
          <Button className="section-label"
                  onClick={() => goToSection('about')}>
            About me
          </Button>
          <Button className="section-label"
                  onClick={() => goToSection(' contact')}>
            Contact me
          </Button>
          <Button className="section-label"
                  onClick={() => goToSection(' follow')}>
            Follow me
          </Button>
        </div>
      </div>

      <div className="right-side">
        <div className="updates-label">
          To get updates register here
        </div>
        <div className="register-email-container">
          <TextField className="email-field"
                     id="email-field"
                     margin="normal"
                     placeholder="email@example.com"
                     type="email"/>
          <Button className="submit-email-button"
                  variant="outlined"
                  onClick={registerEmailForUpdates}>
            Submit
          </Button>
        </div>
      </div>

      <div className="copyright-label">
        ©{getCurrentYear()} Copyright Fernando Ramirez. All Rights Reserved
      </div>
    </div>
  );
};

export default MainFooter;
