import React from 'react';
import HomePage from './src/_components/_pages/HomePage/HomePage';
import ReactDOM from 'react-dom';
import PostPage from './src/_components/_pages/PostPage/PostPage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFoundPage from './src/_components/_pages/NotFoundPage/NotFoundPage';
import { applyMiddleware, createStore, compose } from 'redux';
import reducers from './src/_redux/reducers';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import Header from './src/_components/Header/Header';
import MainFooter from './src/_components/MainFooter/MainFooter';
import CreatePostPage from './src/_components/_pages/CreatePostPage/CreatePostPage';
import Firebase, { FirebaseContext } from './src/_components/Firebase';
import RegisterPage from './src/_components/_pages/AuthPages/RegisterPage/RegisterPage';
import LoginPage from './src/_components/_pages/AuthPages/LoginPage/LoginPage';
import { getUserByFirebaseId } from './src/_redux/actions/user';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers,
  composeEnhancers(applyMiddleware(thunk)));

const App = props => {
  const { firebase } = props;
  const [authUser, setAuthUser] = React.useState(null);

  React.useEffect(() => {
    firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        store.dispatch(getUserByFirebaseId(authUser.uid));
      }
      return setAuthUser(authUser ? authUser : null);
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <React.Fragment>
          <Header/>
          <Switch>
            <Route path="/create-post/"
                   render={props => <CreatePostPage {...props} authUser={authUser}/>}
            />

            <Route exact path="/"
                   render={props => <HomePage {...props} authUser={authUser}/>}
            />
            <Route path="/post/:id"
                   render={props => <PostPage {...props} authUser={authUser}/>}
            />
            <Route path="/login"
                   render={props => <LoginPage {...props} authUser={authUser}/>}
            />
            <Route path="/register"
                   render={props => <RegisterPage {...props} authUser={authUser}/>}
            />
            <Route component={NotFoundPage}/>
          </Switch>
          <MainFooter/>
        </React.Fragment>
      </Router>
    </Provider>
  );
};


ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <FirebaseContext.Consumer>
      {
        firebase => <App firebase={firebase}/>
      }
    </FirebaseContext.Consumer>
  </FirebaseContext.Provider>, document.getElementById('app'));
