import React from 'react';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      backgroundColor: 'transparent',
      border: '1px solid #808080',
      color: '#808080',
      fontSize: '12px',
      height: '24px',
      marginRight: 8,
    },
  }),
);

const Tag = (props) => {
  const classes = useStyles();
  const { handleClick, handleDelete, name } = props;
  return (
    <Chip label={`#${name}`}
          onClick={handleClick}
          onDelete={handleDelete}
          className={classes.chip}
    />
  );
};

export default Tag;
