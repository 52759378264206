// Action types
export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_POST_BY_TITLE = 'FETCH_POST_BY_TITLE';
export const SET_POST = 'SET_POST';
export const CREATE_POST = 'CREATE_POST';
export const IS_EDITING = 'IS_EDITING';
export const UPDATE_POST = 'UPDATE_POST';

export const GET_USER_BY_FIREBASE_ID = 'GET_USER_BY_FIREBASE_ID';
export const CREATE_USER_IN_DB = 'CREATE_USER_IN_DB';
export const CREATE_POST_ID = 'CREATE_POST_ID';

// User access levels - THESE MUST MATCH THE API CONSTANTS
export const NORMAL_USER_ACCESS = 1;
export const ADMIN_USER_ACCESS = 2;
