import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tag from '../Tag/Tag';
import CardMedia from '@material-ui/core/CardMedia';
import moment from 'moment';
import { connect } from 'react-redux';
import { setPost } from '../../_redux/actions';
import { formatPostUrlTitle } from '../../utils/AppUtils';

const useStyles = makeStyles(
  createStyles({
    card: {
      height: 335,
      margin: '0 10px',
      position: 'relative',
      width: 350,
    },
    title: {
      cursor: 'pointer',
      height: 65,
      fontFamily: 'Roboto Mono',
      fontWeight: 600,
    },
    subtitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '2.5px 0',
    },
    subtitle: {
      fontFamily: 'Roboto Mono',
      fontSize: 10,
    },
    media: {
      height: 0,
      margin: '20px 0 0px 0',
      paddingTop: '52.25%', // 4% under 16:9
    },
    body: {
      fontSize: 14,
      lineHeight: 1.6,
      margin: '15px 0',
      maxHeight: 170,
      overflow: 'hidden',
      textAlign: 'justify',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre-wrap',
    },
    tagsContainer: {
      bottom: 10,
      display: 'flex',
      position: 'absolute',
    },
  }),
);

const renderPostBody = (body, classes, imageUrl) => {
  if (imageUrl) {
    return (
      <CardMedia className={classes.media}
                 image={imageUrl}
      />
    );
  }
  return (
    <Typography className={classes.body}>
      {body}
    </Typography>
  );
};

const renderTags = (tags) =>
  tags.slice(0, 4).map((tag, index) =>
    <Tag key={index}
         name={tag}/>);

const PostCard = (props) => {
  const classes = useStyles();
  const { history, post, setPost } = props;
  const {
    description, createdAt, imageUrl,
    tags, title,
  } = post;
  const { firstName, lastName } = post.author;

  const navigateToPost = () => {
    setPost(post);
    return history.push(`/post/${formatPostUrlTitle(title)}`);
  };

  return (
    <Card className={classes.card}
          elevation={8}>
      <CardContent>
        <Typography className={classes.title}
                    variant="h5"
                    component="h2"
                    onClick={navigateToPost}>
          {title}
        </Typography>

        <Box className={classes.subtitleContainer}>
          <Typography className={classes.subtitle}
                      color="textSecondary"
                      gutterBottom>
            {firstName} {lastName}
          </Typography>
          <Typography className={classes.subtitle}
                      color="textSecondary"
                      gutterBottom>
            {moment(createdAt).format('MMMM Do, YYYY')}
          </Typography>
        </Box>

        {renderPostBody(description, classes, imageUrl)}

        <Box className={classes.tagsContainer}>
          {renderTags(tags)}
        </Box>
      </CardContent>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setPost: (post) => dispatch(setPost(post)),
});

export default connect(null, mapDispatchToProps)(PostCard);
