import React, { Component } from 'react';
import { Card, CardContent } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import '../AuthPage.scss';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withFirebase } from '../../../Firebase';
import { withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { saveUserToDb } from '../../../../_redux/actions/user';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  isSnackbarOpen: false,
  snackbarMessage: '',
};

class RegisterPage extends Component {
  constructor(props) {
    super(props);
  }

  state = { ...INITIAL_STATE };

  render() {
    const {
      firstName, lastName, email, password,
      isSnackbarOpen, snackbarMessage,
    } = this.state;
    const { firebase, history, saveUserToDb } = this.props;

    const hideSnackbar = () => this.setState({ ...INITIAL_STATE, isSnackbarOpen: false });

    const onSubmit = event => {
      event.preventDefault();

      const user = {
        firebaseUid: '',
        firstName,
        lastName,
        email,
        password,
        passwordConfirm: password,
      };

      firebase
        .doCreateUserWithEmailAndPassword(email, password)
        .then(authUser => {
          this.setState({
            ...INITIAL_STATE,
            isSnackbarOpen: true,
            snackbarMessage: 'Registration successful',
          });

          user.firebaseUid = authUser.user.uid;

          saveUserToDb(user)
            .then(() => history.push('/'))
            .catch(error => console.error(error));
        })
        .catch(error => {
          this.setState({ error, isSnackbarOpen: true, snackbarMessage: error.message });
        });
    };

    const onChange = event =>
      this.setState({ [event.target.name]: event.target.value });

    const isInvalid =
      firstName.trim() === '' ||
      lastName.trim() === '' ||
      password.trim() === '' ||
      email.trim() === '';

    return (
      <div className="auth-page">
        <Card className="card-form">
          <div className="card-header">
            <div className="title">
              Register
            </div>
            <div className="subtitle">
              framirez.dev
            </div>
          </div>

          <CardContent>
            <form className="form"
                  onSubmit={onSubmit}>
              <div className="form-group">
                <FormControl className="form-field">
                  <TextField label="First Name"
                             InputLabelProps={{
                               shrink: true,
                             }}
                             name="firstName"
                             onChange={onChange}
                             placeholder="Luke"
                             required
                             type="text"
                             value={firstName}/>
                  <FormHelperText className="form-label">
                    First name is required
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="form-group">
                <FormControl className="form-field">
                  <TextField label="Last Name"
                             InputLabelProps={{
                               shrink: true,
                             }}
                             name="lastName"
                             onChange={onChange}
                             placeholder="Skywalker"
                             required
                             type="text"
                             value={lastName}/>
                  <FormHelperText className="form-label">
                    Last name is required
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="form-group">
                <FormControl className="form-field">
                  <TextField label="Email"
                             InputLabelProps={{
                               shrink: true,
                             }}
                             name="email"
                             onChange={onChange}
                             placeholder="email@example.com"
                             required
                             type="email"
                             value={email}/>
                  <FormHelperText className="form-label">
                    Email is required
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="form-group">
                <FormControl className="form-field">
                  <TextField label="Password"
                             InputLabelProps={{
                               shrink: true,
                             }}
                             name="password"
                             onChange={onChange}
                             placeholder="Password"
                             required
                             type="password"
                             value={password}/>
                  <FormHelperText className="form-label">
                    Password is required
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="login-button-container">
                <Button className="login-button"
                        disabled={isInvalid}
                        type="submit">
                  Register
                </Button>
              </div>

              <div className="warning-message-container">
                <div className=" label">
                  Lorem ipsum dolor sit amet, ius error iuvaret ne, elit labore quidam vis in.
                  Deleniti erroribus principes mei ut
                </div>
              </div>
            </form>
          </CardContent>
        </Card>

        <Snackbar
          autoHideDuration={2500}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          key="bottom,center"
          open={isSnackbarOpen}
          onClose={hideSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
            {snackbarMessage}
          </span>
          }
        />
      </div>

    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveUserToDb: (user) => dispatch(saveUserToDb(user)),
});

const enhance = compose(
  withRouter,
  withFirebase,
  connect(null, mapDispatchToProps),
);

export default enhance(RegisterPage);
