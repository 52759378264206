import { CREATE_USER_IN_DB, GET_USER_BY_FIREBASE_ID } from '../../../constants';

export default (state = null, action) => {
  switch (action.type) {
    case GET_USER_BY_FIREBASE_ID:
      return action.payload;
    case CREATE_USER_IN_DB:
      return action.payload;
    default:
      return state;
  }
};
