import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { createPost, createPostId, updatePost } from '../../../_redux/actions';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from '../../Firebase/Session/withAuthorization';
import '../PostPage/PostPage.scss';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Tag from '../../Tag/Tag';
import moment from 'moment';
import { getUserByFirebaseId } from '../../../_redux/actions/user';
import LinearProgress from '@material-ui/core/LinearProgress';
import app from 'firebase/app';
import 'firebase/storage';
import Img from 'react-image';
import QuickHoverAccess from '../../QuickHoverAccess/QuickHoverAccess';
import { formatPostUrlTitle } from '../../../utils/AppUtils';
import { ADMIN_USER_ACCESS } from '../../../../constants';
import Highlight from 'react-highlight';

let firebaseAuthUser = null;

const CreatePostPage = (props) => {
  const {
    createPost, createPostId, getUserByFirebaseId, history,
    isEditing, post, updatePost, user,
  } = props;
  const editorInit = {
    height: '600px',
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen hr',
      'insertdatetime media table contextmenu paste spellchecker',
      'wordcount codesample',
    ],
    toolbar: 'undo redo | ' +
      'styleselect | ' +
      'bold italic forecolor backcolor | ' +
      'alignleft aligncenter alignright alignjustify | ' +
      'bullist numlist outdent indent | ' +
      'link image spellchecker code codesample',
    image_advtab: true,
    file_picker_callback: (cb, value, meta) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.onchange = () => {
        const file = input.files[0];
        const filePath = `posts/${postId}/${formatFilename(file.name)}`;
        setIsLoading(true);

        uploadFile(file, filePath)
          .then((downloadUrl) => {
            const reader = new FileReader();
            reader.onload = () => {
              // call the callback and populate the Title field with the file name
              cb(downloadUrl, { title: file.name });
              setIsLoading(false);
            };
            reader.readAsDataURL(file);
          });
      };
      input.click();
    },
  };
  const currentDate = Date.now();
  const UPLOAD_POST_IMAGE_HIDDEN_INPUT = 'upload-post-image-hidden-input';
  const TITLE_MAX_LENGTH = 75;

  const [showPostPreview, setShowPostPreview] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadingPostImage, setIsUploadingPostImage] = React.useState(false);
  const [postTitleInputInvalid, setPostTitleInputInvalid] = React.useState(false);
  const [postDescriptionInputInvalid, setPostDescriptionInputInvalid] = React.useState(false);

  // Post specific states
  const [postId, setPostId] = React.useState(props.postId);
  const [editorState, setEditorState] = React.useState('');
  const [postInputTitle, setPostInputTitle] = React.useState('');
  const [postInputDescription, setPostInputDescription] = React.useState('');
  const [postBodyInvalid, setPostBodyInvalid] = React.useState(false);
  const [hasPostImage, setHasPostImage] = React.useState(false);
  const [postImageUrl, setPostImageUrl] = React.useState('');
  const [postTypeDropdownValue, setPostTypeDropdownValue] = React.useState('thoughts');
  const [postTags, setPostTags] = React.useState([]);
  const [tagInputValue, setTagInputValue] = React.useState('');

  React.useEffect(() => {
    createPostId();
  }, []);

  React.useEffect(() => {
    if (firebaseAuthUser) {
      getUserByFirebaseId(firebaseAuthUser.uid);
    }
  }, [firebaseAuthUser]);

  React.useEffect(() => {
    if (post) {
      setPostId(post._id);
      setEditorState(post.body);
      setPostInputTitle(post.title);
      setPostInputDescription(post.description);
      setPostTypeDropdownValue(post.postType);
      setPostTags(post.tags);
      setHasPostImage(true);
      setPostImageUrl(post.imageUrl);
    }
  }, [isEditing, post]);


  // This isn't the best way to do it, but it works.
  // It's just a tad bit slow
  React.useEffect(() => {
    if (user && user.accessLevel < ADMIN_USER_ACCESS) {
      history.push('/');
    }
  }, [user]);

  const onEditorStateChange = (content) => setEditorState(content);

  const onPostTitleInputChange = e => setPostInputTitle(e.target.value);
  const onPostDescriptionInputChange = e => setPostInputDescription(e.target.value);
  const onTagInputChange = e => setTagInputValue(e.target.value);

  const onPostTypeChange = e => setPostTypeDropdownValue(e.target.value);

  const onTagInputEnterPress = (event) => {
    if (event.key === 'Enter') {
      if (tagInputValue.trim().length > 0) {
        // Check if user already added the same tag
        if (postTags.includes(tagInputValue)) {
          // TODO: Show snackbar error
          console.error(`Tag: ${tagInputValue} already exists`);
          setTagInputValue('');
        } else {
          setPostTags([...postTags, tagInputValue]);
          setTagInputValue('');
          event.preventDefault();
        }
      }
    }
  };

  // Add 'header' to beginning, make lowercase, & replace any spaces with '-'
  const formatPostImage = (fileName) => {
    let replaced = fileName;
    replaced = replaced.toLowerCase();
    replaced = replaced.replace(/(^\s+|[^a-zA-Z0-9 ]+\.-+|\s+$)/g, '');
    replaced = replaced.replace(/\s+/g, '-');
    return `${moment().format('MM-DD-YYYY')}-header-${replaced}`;
  };

  // Format file name to lowercase & replace any spaces with '-'
  const formatFilename = (fileName) => {
    let replaced = fileName;
    replaced = replaced.toLowerCase();
    replaced = replaced.replace(/(^\s+|[^a-zA-Z0-9 ]+\.-+|\s+$)/g, '');
    replaced = replaced.replace(/\s+/g, '-');
    return `${moment().format('MM-DD-YYYY')}-body-${replaced}`;
  };

  const uploadFile = (file, filePath) => {
    return new Promise((resolve) => {
      const fileRef = app.storage().ref(filePath);
      const task = fileRef.child(file.name);

      task.put(file)
        .then(() =>
          task.getDownloadURL()
            .then(url => resolve(url)));
    });
  };

  const uploadPostImage = () => {
    const input = document.getElementById(UPLOAD_POST_IMAGE_HIDDEN_INPUT);

    input.onchange = () => {
      const file = input.files[0];
      const filePath = `posts/${postId}/${formatPostImage(file.name)}`;
      setIsUploadingPostImage(true);

      uploadFile(file, filePath)
        .then((downloadUrl) => {
          const reader = new FileReader();
          reader.onload = () => {
            setPostImageUrl(downloadUrl);
            setIsUploadingPostImage(false);
            setHasPostImage(true);
          };
          reader.readAsDataURL(file);
        });
    };
    input.click();
  };

  const verifyPost = (onVerifySuccess) => {
    let titleInputValid = false;
    let descriptionInputValid = false;
    let bodyInputValid = false;

    if (postInputTitle.trim().length === 0) {
      setPostTitleInputInvalid(true);
    } else {
      setPostTitleInputInvalid(false);
      titleInputValid = true;
    }

    if (postInputDescription.trim().length === 0) {
      setPostDescriptionInputInvalid(true);
    } else {
      setPostDescriptionInputInvalid(false);
      descriptionInputValid = true;
    }

    if (editorState.trim().length === 0) {
      setPostBodyInvalid(true);
    } else {
      setPostBodyInvalid(false);
      bodyInputValid = true;
    }

    if (titleInputValid && descriptionInputValid && bodyInputValid) {
      onVerifySuccess();
    }
  };

  const attemptToCreatePost = async () => {
    const post = {
      author: user,
      body: editorState,
      description: postInputDescription,
      tags: postTags,
      title: postInputTitle,
      imageUrl: postImageUrl,
      postType: postTypeDropdownValue,
    };

    try {
      setIsLoading(true);
      await createPost(post);
      // TODO: Show snackbar 'Successfully created post'
      history.push('/');
    } catch (error) {
      setIsLoading(false);
      // TODO: Show snackbar 'An error occurred trying to create post - Please try again'
      console.error(error);
    }
  };

  const attemptToUpdatePost = async () => {
    const post = {
      _id: postId,
      body: editorState,
      description: postInputDescription,
      tags: postTags,
      title: postInputTitle,
      imageUrl: postImageUrl,
      postType: postTypeDropdownValue,
    };

    try {
      setIsLoading(true);
      await updatePost(post);
      // TODO: Show snackbar 'Successfully created post'
      history.push(`/post/${formatPostUrlTitle(post.title)}`);
    } catch (error) {
      setIsLoading(false);
      // TODO: Show snackbar 'An error occurred trying to create post - Please try again'
      console.error(error);
    }
  };

  const renderLoadingSpinner = (isLoading) => {
    if (isLoading) {
      return (
        <div className="loading-spinner-container">
          <CircularProgress className="loading-spinner"/>
        </div>
      );
    }
  };

  const renderTags = (tags) =>
    tags.slice(0, 4).map((tag, index) =>
      <Tag key={index}
           handleClick={null}
           handleDelete={() => deleteTag(tag)}
           name={tag}/>);

  const renderPreviewButtons = () => {
    return (
      showPostPreview ?
        <Button className="action-button"
                disabled={isLoading}
                onClick={() => setShowPostPreview(false)}>
          Hide Post Preview
        </Button>
        :
        <Button className="action-button"
                disabled={isLoading}
                onClick={() => setShowPostPreview(true)}>
          Show Post Preview
        </Button>
    );
  };

  const deleteTag = (tagNameToDelete) => {
    setPostTags(postTags.filter((tagName) =>
      tagName !== tagNameToDelete));
  };

  return (
    <div className="post-page">
      <QuickHoverAccess showHomeButton
                        showThoughtsButton
                        showTutorialsButton
                        history={history}
      />
      <div className="post-content">
        {renderLoadingSpinner(isLoading)}

        <div className="post-header-container">
          <div className="post-title">
            {postInputTitle}
          </div>
          <div className="post-subtitle">
            <div className="post-author">
              {user && `${user.firstName} ${user.lastName}`}
            </div>
            <span className="spacer"/>
            <div className="post-created-date">
              {moment(currentDate).format('MMMM Do, YYYY')}
            </div>
          </div>
        </div>

        <div className="create-post-header-container">
          <FormControl className="form-field">
            <TextField label="Title"
                       inputProps={{ maxLength: TITLE_MAX_LENGTH }}
                       helperText={
                         <span className={'input-helper-text'}>
                           {postInputTitle.length}/{TITLE_MAX_LENGTH}
                         </span>
                       }
                       fullWidth
                       name="title"
                       placeholder="Title"
                       onChange={onPostTitleInputChange}
                       required
                       type="text"
                       value={postInputTitle}/>
            {postTitleInputInvalid &&
            <FormHelperText className="form-label form-label-error">
              Title is required
            </FormHelperText>}
          </FormControl>

          <FormControl className="form-field description-form-field">
            <TextField
              fullWidth
              required
              onChange={onPostDescriptionInputChange}
              label="Description"
              placeholder="Description"
              multiline
              value={postInputDescription}
            />
            {postDescriptionInputInvalid &&
            <FormHelperText className="form-label form-label-error">
              Description is required
            </FormHelperText>}
          </FormControl>

          <Box className="create-tags-container">
            {renderTags(postTags)}

            <TextField
              className="tags-text-field"
              onChange={onTagInputChange}
              onKeyPress={onTagInputEnterPress}
              placeholder="Tags"
              value={tagInputValue}
            />
          </Box>

          <div className="type-of-post-container">
            <FormControl className="post-type-dropdown">
              <InputLabel htmlFor="post-type">
                Type of post
              </InputLabel>
              <Select
                value={postTypeDropdownValue}
                onChange={onPostTypeChange}
                inputProps={{
                  name: 'postTypeDropdownValue',
                  id: 'post-type',
                }}
              >
                <MenuItem value='thoughts'>
                  Thoughts
                </MenuItem>
                <MenuItem value='tutorials'>
                  Tutorials
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="upload-post-image-container">
            {hasPostImage ?
              <Img alt="Post image"
                   className="default-image"
                   src={postImageUrl}/>
              :
              <Box className="no-image-selected"/>
            }

            <Button className="upload-button"
                    onClick={uploadPostImage}>
              Upload post image
            </Button>

            <input id={UPLOAD_POST_IMAGE_HIDDEN_INPUT}
                   className="hidden-input"
                   type="file"
                   accept="image/*"/>

            <div className="image-size-tip">
              Recommended size is 450x450
            </div>
          </div>

          {uploadingPostImage &&
          <div className="upload-image-bar">
            <LinearProgress/>
          </div>}
        </div>

        <Editor
          apiKey={process.env.EDITOR_API_KEY}
          onEditorChange={onEditorStateChange}
          init={editorInit}
          value={editorState}
        />

        <div className="editor-hint-container">
          {postBodyInvalid &&
          <FormHelperText className="form-label form-label-error">
            Body is required
          </FormHelperText>
          }
        </div>

        <div className="action-buttons-container">
          {isEditing ?
            <Button className="action-button"
                    disabled={isLoading}
                    onClick={() => verifyPost(attemptToUpdatePost)}>
              Update Post
            </Button>
            :
            <Button className="action-button"
                    disabled={isLoading}
                    onClick={() => verifyPost(attemptToCreatePost)}>
              Create Post
            </Button>
          }

          {renderPreviewButtons()}
        </div>

        {showPostPreview &&
        <div className="post-body">
          <Highlight innerHTML={true}>
            {editorState}
          </Highlight>
        </div>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isEditing: state.posts.isEditing,
  post: state.posts.post,
  postId: state.posts.postId,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  createPost: (post) => dispatch(createPost(post)),
  createPostId: () => dispatch(createPostId()),
  getUserByFirebaseId: (id) => dispatch(getUserByFirebaseId(id)),
  updatePost: (post) => dispatch(updatePost(post)),
});

const userLoggedIn = authUser => {
  firebaseAuthUser = authUser;
  return !!authUser;
};

const enhance = compose(
  withAuthorization(userLoggedIn),
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(CreatePostPage);
