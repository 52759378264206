export const formatPostUrlTitle = s => {
  const HYPHEN_CODE = 'hyphen';
  // Handle hyphens
  if (s.includes('-')) {
    s = s.replace('-', HYPHEN_CODE);
  }

  // Replace spaces with hyphens
  s = s.replace(/\s/g, '-');
  return s;
};
