import React from 'react';
import './NewPosts.scss';
import PostCard from '../PostCard/PostCard';
import { connect } from 'react-redux';
import { fetchPosts } from '../../_redux/actions';

const renderPosts = (history, posts) => {
  return posts.slice(0, 3).map(post =>
    <PostCard key={post._id}
              history={history}
              post={post}/>);
};

const NewPosts = (props) => {
  const { history, posts } = props;

  React.useEffect(() => {
    props.fetchPosts();
  }, []);

  return (
    <div className="new-posts">
      <div className="new-posts-label">
        New Posts
      </div>

      <div className="cards-container">
        {renderPosts(history, posts)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return ({
    posts: state.posts.posts,
  });
};

const mapDispatchToProps = (dispatch) => ({
  fetchPosts: () => dispatch(fetchPosts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPosts);
