import React from 'react';
import mindMapIconInverted from '../../assets/mind-map-icon-inverted.svg';
import aiIcon from '../../assets/ai-icon.svg';
import appLogoIcon from '../../assets/app-logo.svg';
import './QuickHoverAccess.scss';
import Button from '@material-ui/core/Button';
import Img from 'react-image';

const QuickHoverAccess = (props) => {
  const {
    history,
    showThoughtsButton,
    showTutorialsButton,
    showHomeButton,
  } = props;

  const goToPage = (pageType) => history.push(`/${pageType}`);

  return (
    <div className="quick-hover-access">
      {showThoughtsButton && (
        <Button className="hover-button thoughts-button"
                variant="text"
                onClick={() => goToPage('thoughts')}
                style={{ top: showTutorialsButton ? '0' : '53px' }}>
          <span className="hover-button-label">
            Thoughts
          </span>
          <img className="toolbar-icon"
               src={mindMapIconInverted}
               alt="mind map icon"/>
        </Button>
      )}

      {showTutorialsButton && (
        <Button className="hover-button tutorials-button"
                variant="text"
                onClick={() => goToPage('tutorials')}
                style={{ top: showTutorialsButton ? '53px' : '106px' }}>
          <span className="hover-button-label">
            Tutorials
          </span>
          <img className="toolbar-icon"
               src={aiIcon}
               alt="ai icon"/>
        </Button>
      )}

      {showHomeButton && (
        <Button className="hover-button home-button"
                variant="text"
                onClick={() => goToPage('')}
                style={{ top: showTutorialsButton ? '106px' : '53px' }}>
          <span className="hover-button-label">
            Home
          </span>
          <Img className="toolbar-icon"
               src={appLogoIcon}
               alt="app logo icon"/>
        </Button>
      )}
    </div>
  );
};

export default QuickHoverAccess;
