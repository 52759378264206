import React, { Component } from 'react';
import { Card, CardContent } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import '../AuthPage.scss';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar';
import { compose } from 'recompose';
import { withFirebase } from '../../../Firebase';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserByFirebaseId } from '../../../../_redux/actions/user';

const INITIAL_STATE = {
  email: '',
  password: '',
  isSnackbarOpen: false,
  snackbarMessage: '',
};

class LoginPage extends Component {
  constructor(props) {
    super(props);
  }

  state = { ...INITIAL_STATE };

  render() {
    const {
      email, password, isSnackbarOpen, snackbarMessage,
    } = this.state;
    const { firebase, getUserByFirebaseId, history } = this.props;

    const hideSnackbar = () => this.setState({ ...INITIAL_STATE, isSnackbarOpen: false });

    const onSubmit = event => {
      event.preventDefault();

      firebase
        .doSignInWithEmailAndPassword(email, password)
        .then((authUser) => {
          this.setState({
            ...INITIAL_STATE,
            isSnackbarOpen: true,
            snackbarMessage: 'Login successful',
          });

          getUserByFirebaseId(authUser.user.uid)
            .then(() => history.push('/'))
            .catch(error => console.error(error));
        })
        .catch(error => {
          this.setState({ error, isSnackbarOpen: true, snackbarMessage: error.message });
        });
    };

    const onChange = event =>
      this.setState({ [event.target.name]: event.target.value });

    const isInvalid = email.trim() === '' || password.trim() === '';

    return (
      <div className="auth-page">
        <Card className="card-form">
          <div className="card-header">
            <div className="title">
              Login To
            </div>
            <div className="subtitle">
              framirez.dev
            </div>
          </div>

          <CardContent>
            <form className="form"
                  onSubmit={onSubmit}>
              <div className="form-group">
                <FormControl className="form-field">
                  <TextField label="Email"
                             InputLabelProps={{
                               shrink: true,
                             }}
                             name="email"
                             onChange={onChange}
                             placeholder="email@example.com"
                             required
                             type="email"
                             value={email}/>
                  <FormHelperText className="form-label">
                    Email is required
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="form-group">
                <FormControl className="form-field">
                  <TextField label="Password"
                             InputLabelProps={{
                               shrink: true,
                             }}
                             name="password"
                             onChange={onChange}
                             placeholder="Password"
                             required
                             type="password"
                             value={password}/>
                  <FormHelperText className="form-label">
                    Password is required
                  </FormHelperText>
                </FormControl>
              </div>

              <div className="login-button-container">
                <Button className="login-button"
                        disabled={isInvalid}
                        type="submit">
                  Login
                </Button>
              </div>

              <div className="warning-message-container">
                <div className=" label">
                  Lorem ipsum dolor sit amet, ius error iuvaret ne, elit labore quidam vis in.
                  Deleniti erroribus principes mei ut
                </div>
              </div>
            </form>
          </CardContent>
        </Card>

        <Snackbar
          autoHideDuration={2500}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          key="bottom,center"
          open={isSnackbarOpen}
          onClose={hideSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span id="message-id">
            {snackbarMessage}
          </span>
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserByFirebaseId: (id) => dispatch(getUserByFirebaseId(id)),
});

const enhance = compose(
  withRouter,
  withFirebase,
  connect(null, mapDispatchToProps),
);

export default enhance(LoginPage);
