import {
  CREATE_POST,
  CREATE_POST_ID,
  FETCH_POST_BY_TITLE,
  FETCH_POSTS,
  IS_EDITING,
  SET_POST, UPDATE_POST,
} from '../../../constants';

const initialState = {
  isEditing: false,
  post: null,
  posts: [],
  postId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case FETCH_POST_BY_TITLE:
      return {
        ...state,
        post: action.payload,
      };
    case SET_POST:
      return {
        ...state,
        post: action.payload,
      };
    case CREATE_POST:
      return {
        ...state,
        posts: [...state.posts, action.payload],
      };
    case CREATE_POST_ID:
      return {
        ...state,
        postId: action.payload,
      };
    case IS_EDITING:
      return {
        ...state,
        isEditing: action.payload,
      };
    case UPDATE_POST:
      return {
        ...state,
        post: action.payload,
      };
    default:
      return state;
  }
};
